<template>
  <div class="aprovacao-item">
    {{habilitacao.arrematante.pessoa.name}}<br>
    LOGIN: <strong>{{habilitacao.arrematante.apelido}}</strong>
    <br>
    ID Leilão: {{habilitacao.leilao.id}}
    <br>
    Data: {{habilitacao.createdAt|formatDate}}
    <br>
    <br>
    <small><a @click="gotoLeilao(habilitacao.leilao)">{{habilitacao.leilao.descricaoInterna}}</a></small>
    <br>
    Data do leilão: <span v-if="habilitacao.leilao.dataProximoLeilao">{{habilitacao.leilao.dataProximoLeilao.date|formatDate}}</span>
    <div class="m-t-md">
      <e-btn @click="gotoLeilaoHabilitacao(habilitacao.leilao)" label="Ir para Aprovação" class="full-width" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HabilitacaoItemLote',
  props: ['habilitacao'],
  methods: {
    gotoLeilao (p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'leiloes.page', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    },
    gotoLeilaoHabilitacao (p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'leiloes.habilitacoes', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
