<script>
  import Mixin from '../mixin'
  import AprovacaoItem from './habilitacao/Item'
  import AprovacaoItemLote from './habilitacao/ItemLote'
  import windowArrematante from '../windows/arrematante'
  import {list, aprovar, reprovar} from '@/domain/arrematante/services/arrematante-habilitacao'
  import {list as listHabLote} from '@/domain/arrematante/services/arrematante-habilitacao-lote'

  export default {
  name: 'MonitorHabilitacaoArrematantes',
  mixins: [Mixin],
  components: {
    AprovacaoItem,
    AprovacaoItemLote
  },
  data () {
    return {
      loading: false,
      habilitacoes: null,
      habilitacoesLote: null,
    }
  },
  computed: {},
  created () {
    this.load()
  },
  methods: {
    windowArrematante: windowArrematante,
    load () {
      this.loading = true
      list(1000, 1, '')
              .then(response => {
                console.log(response.data)
                this.loading = false
                this.habilitacoes = response.data
              })
              .catch(error => {
                this.loading = false
                console.log(error)
              })
      listHabLote(1000, 1, '')
              .then(response => {
                console.log(response.data)
                this.loading = false
                this.habilitacoesLote = response.data
              })
              .catch(error => {
                this.loading = false
                console.log(error)
              })
    },
    aprovar (habilitacao) {
      aprovar(habilitacao.id)
      .then(({data}) => {
        this.load()
        this.$uloc.notify({color: 'positive', message: habilitacao.arrematante.apelido + ' habilitado com sucesso'})
      })
      .catch(error => {
        this.alertApiError(error)
      })
    },
    reprovar (habilitacao) {
      reprovar(habilitacao.id)
      .then(({data}) => {
        this.load()
        this.$uloc.notify({color: 'negative', message: habilitacao.arrematante.apelido + ' teve a habilitação reprovada.'})
      })
      .catch(error => {
        this.alertApiError(error)
      })
    }
  },
  meta: {
    title: 'Habilitação em Leilão - Aprovação',
    name: 'Habilitação em Leilão - Aprovação'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <div class="wrapper-lg">
            <div>
              <span class="font-11 hide">Recarregando em 30</span> <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="loading" />
            </div>
            <p class="font-12 text-blue-grey-8">Somente arrematantes que preencheram os requisitos mínimos para análise
              cadastral estarão disponíveis aqui. </p>
            <div class="aprovacao-container">
              <aprovacao-item @verArrematante="windowArrematante(habilitacao.arrematante.id)" @aprovar="aprovar(habilitacao)" @reprovar="reprovar(habilitacao)" v-for="habilitacao in habilitacoes" :key="habilitacao.id" :habilitacao="habilitacao" />
            </div>
            <p class="m-t-lg">Habilitações em Lote e Direito de Perferência</p>
            <div class="aprovacao-container bg-grey-5 wrapper b-radius-3px">
              <aprovacao-item-lote @verArrematante="windowArrematante(habilitacao.arrematante.id)" @aprovar="aprovar(habilitacao)" @reprovar="reprovar(habilitacao)" v-for="habilitacao in habilitacoesLote" :key="'hl-' + habilitacao.id" :habilitacao="habilitacao" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="stylus">
  .aprovacao-container {
    margin -10px
    display flex
    flex-wrap: wrap

    .aprovacao-item {
      position relative
      min-width: 260px;
      max-width: 260px;
      min-height: 124px;
      flex: 1;
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      padding: 14px 20px;
      margin: 10px;
      transition: all 0.5s;
      // cursor: pointer;
      line-height 1.2

      .user-image {
        position absolute
        width 25px
        height 25px
        left -12px
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius 500px
      }
    }
  }
</style>

