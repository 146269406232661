import http from '../../../utils/services/http'
import {download} from "@/domain/leiloes/services/financeiro";

export const list = (limit, page, filtros, exportar = false) => {
  let url = '/api/habilitacaoArrematante/list?page=' + page + '&limit=' + limit + filtros
  const config = {}
  if (exportar){
    config.responseType = 'blob'
  }
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const aprovar = (id) => {
  let url = `/api/habilitacaoArrematante/${id}/aprovar`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const reprovar = (id) => {
  let url = `/api/habilitacaoArrematante/${id}/reprovar`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const habilitar = (arrematante, leilao) => {
  let url = `/api/habilitarArrematante/${arrematante}/${leilao}`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const excluir = (id) => {
  let url = `/api/habilitacaoArrematante/${id}/excluir`
  return http.put(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const downloadTermoHabilitacao = (leilao, habilitacao) => {
  return download(`/api/arrematantes/service/leiloes/${leilao}/downloadTermoHabilitacao?habilitacao=${habilitacao}`)
}
