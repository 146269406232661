<template>
  <div class="aprovacao-item">
    {{habilitacao.arrematante.pessoa.name}}<br>
    LOGIN: <strong>{{habilitacao.arrematante.apelido}}</strong>
    <br>
    ID Leilão: {{habilitacao.leilao.id}}
    <br>
    Data: {{habilitacao.dataHabilitacao.date|formatDate}}
    <br>
    <br>
    <small><a @click="gotoLeilao(habilitacao.leilao)">{{habilitacao.leilao.descricaoInterna}}</a></small>
    <br>
    Data do leilão: <span v-if="habilitacao.leilao.dataProximoLeilao">{{habilitacao.leilao.dataProximoLeilao.date|formatDate}}</span>
    <div class="m-t-md">
      <e-btn label="Ver arrematante" class="full-width" @click="$emit('verArrematante')" />
      <div class="m-t-xs" style="display: flex; justify-content: space-between">
        <div style="flex: 1; padding-right: 5px"><e-btn label="Aprovar" class="full-width" color="positive" @click="$emit('aprovar')" /></div>
        <div style="flex: 1; padding-left: 5px"><e-btn label="Reprovar" class="full-width m-l-xs" @click="$emit('reprovar')" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HabilitacaoItem',
  props: ['habilitacao'],
  methods: {
    gotoLeilao (p, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'leiloes.page', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
